.boxContent {
    padding: 24px 24px 1px 24px;
    border-radius: 0px 8px 0px 0px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.boxLayout {
    padding: 0px 24px 24px 24px;
    border-radius: 0px 0px 8px 8px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.buttonPadding {
    padding-top: 8px;
    padding-right: 22px;
    padding-bottom: 8px;
    padding-left: 22px;
}