
.tabLayout {
    font-weight: 700;
    background-color: #f5f5f5;
    color: #015e88 !important;
}

.searchIcon {
    color: #620000;
    font-size: 30px;
    -webkit-text-fill-color: transparent;
}

.addressSearchText {
    display: flex;
    align-items: center;
    padding: 25px 0px;
    font-weight: 700;
    background: -webkit-linear-gradient(#9e2339, #620000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}