.borderSection {
    /* border: 1px solid #BDBDBD; */
    border-radius: 8px;
}

.lastLineBorder {
    border-bottom: 1px solid #BDBDBD !important;
    /* padding-bottom: 20px !important; */
}

.lastLineBorder>.firstgriditem {
    border: none;
}

.topPadding {
    padding-top: 0px;
}

.exceptionDisplayBlock {
    display: block !important;
}

@media (max-width: 1199px) {
    .max960space {
        display: block;
    }
}


/* New CSS */

.parcelList {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
}

.parcelListData {
    flex: 0 0 25%;
    margin: 5px 0;
    min-height: 55px;
}

/* .anotherClass :first-child {
    flex: 0 0 1%;
} */

.parcelListDataOneSection {
    flex: 0 0 97%;
    margin: 5px 0;
    min-height: 55px;
}

.parcelListDataTwoSection {
    flex: 0 0 50%;
    margin: 5px 0;
    min-height: 55px;
}

.headerQuestion {
    font-weight: 700;
}

.headerAnswer {
    display: block;
}

.exceptionAlignment {
    /* margin-top: 15px; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 15px;
    /* padding-left: 5px; */
    border-radius: 4px;
}

.apnListAlignment {
    background-color: #F5F5F5;
    margin-top: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 4px;
    position: relative;
    padding-left: 30px;
}

.headerDetailsPadding {
    padding-left: 14px;
}

.arrowAlignment {
    padding-left: 6px;
    padding-right: 10px;
    line-height: 3.5;
}

.exceptionPadding {
    padding-left: 40px;
}

.claimAlertAlignment {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 8px
}

.documentImageAlignment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.arrowPosition {
    display: flex;
    flex-direction: column-reverse;
    padding-right: 10px;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 13px;
    padding-left: 5px;
}