.boxLayout {
    padding: 24px;
    background-color: white;
    border-radius: 2px 5px 8px 8px;
    box-shadow: 1px 5px 8px 1px #c3c3c3
}

.buttonPadding {
    padding-top: 8px;
    padding-right: 22px;
    padding-bottom: 8px;
    padding-left: 22px;
}