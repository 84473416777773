.addressSearchText {
    display: flex;
    align-items: center;
    padding: 25px 0px;
    font-weight: 700;
    background: -webkit-linear-gradient(#9e2339, #620000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.searchIcon {
    font-size: 26px;
    color: #620000;
    -webkit-text-fill-color: transparent;
}

.boxLayout {
    background-color: white;
    border-radius: 8px 8px 8px 8px;
    /* box-shadow: 1px 5px 8px 1px #c3c3c3; */
    max-height: auto;
}

.parcelList {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5px;
}

.parcelListData {
    flex: 0 0 25%;
    margin: 5px 0;
    min-height: 55px;
}

.parcelListDataOneSection {
    flex: 0 0 98%;
    margin: 5px 0;
    min-height: 55px;
}

.parcelListDataTwoSection {
    flex: 0 0 50%;
    margin: 5px 0;
    min-height: 55px;
}

.headerQuestion {
    font-weight: 700;
}

.headerAnswer {
    display: block;
}

.sectionPadding {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0;
}

.redText {
    color: red;
}