.alignSection {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.errorCode {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 10rem;
    font-weight: 600;
    line-height: 1.2;
    background: -webkit-linear-gradient(#7F7F7F, #555555);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.notFoundText {
    font-size: 3rem;
    font-weight: 600;
    line-height: 1;
}