body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root.MuiDialog-paper {
  /* box-shadow: none; */
  /* background-color: transparent; */
}

/* .MuiPaper-root.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  box-shadow: none;
  background-color: transparent;
} */

/* .MuiDialogContent-root.css-ypiqx9-MuiDialogContent-root {
  padding: 0;
} */

ul {
  list-style: none;
  /* Removes bullet points */
  padding: 0;
  /* Removes default padding */
  margin: 0;
  /* Removes default margin */
}

li {
  margin: 0;
  /* Removes default margin for list items */
  padding: 0;
  /* Removes default padding for list items */
}