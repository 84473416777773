.gradientButton {
    background-image: linear-gradient(#0075aa, #03567b);
    height: 38px;
}

.searchResultBox {
    border: 1px solid;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
    padding-left: 10px;
    margin-top: 10px;
}

.boxLayout {
    padding: 0px 24px 24px 24px;
    border-radius: 0px 0px 8px 8px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

.buttonPadding {
    padding-top: 8px;
    padding-right: 22px;
    padding-bottom: 8px;
    padding-left: 22px;
}