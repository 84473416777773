.gradientButton {
    background-image: linear-gradient(#0075aa, #03567b);
    height: 38px;
}

/* .boxLayout {
    padding: 24px;
    background-color: white;
    border-radius: 2px 5px 8px 8px;
    box-shadow: 1px 5px 8px 1px #c3c3c3
} */

.boxContent {
    padding: 24px 24px 1px 24px;
    border-radius: 0px 8px 0px 0px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.boxLayout {
    padding: 0px 24px 24px 24px;
    border-radius: 0px 0px 8px 8px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.25);
}

/* .boxLayout {
    padding: 24px;
    border-radius: 0px 8px 8px 8px;
    background: var(--stewart-brand-colors-white, #FFF);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
} */

.buttonPadding {
    padding-top: 8px;
    padding-right: 22px;
    padding-bottom: 8px;
    padding-left: 22px;
}

.activeButton {
    background-image: linear-gradient(#0075aa, #03567b);
    text-transform: none;
    box-shadow: 0px 2px 3px 0px #00000040;
    padding: 10px 22px 8px 22px;
}

.activeFontColor {
    font-size: 15px;
    font-weight: 500;
    color: #FFFF
}

.inactiveFontcolor {
    font-size: 15px;
    font-weight: 500;
    color: #015E88
}

.inactiveButton {
    color: #015E88;
    border-color: #015E88;
    text-transform: none;
    box-shadow: 0px 2px 3px 0px #00000040;
    padding: 8px 22px 8px 22px;
}

.helperTextAlign {
    position: absolute;
    left: 0;
    right: 0;
    width: 200px;
    padding-left: 10px;
    top: 90%;
}