.returnBox {
    display: flex;
    justify-content: flex-start;
}

.customButtonStyle {
    border: 1px solid;
    font-weight: 600;
    color: #0075aa;
    margin-left: 10px;
    letter-spacing: normal;
}