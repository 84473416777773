html,
body,
#root {
  height: 100%;
}

.App {
  background-color: #e5e5e5;
  min-height: 100%;
  margin-bottom: -80px;
}

.MuiContainer-root {
  padding-left: 45px;
  padding-right: 45px;
}