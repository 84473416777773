.alignSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    z-index: 10000;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.overlayBlocker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
}

.alignCentred {
    position: fixed;
    top: 45%;
    z-index: 99999;
}

.loaderOverflowHidden {
    overflow: hidden;
}