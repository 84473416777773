.navbar {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    height: 80px;
    font-size: 12px !important;
    font-weight: 600;
    border-radius: 0px 0px 7px 7px;
}

.logoAlign {
    padding-left: 25px;
}