.ExceptionStyle a {
    pointer-events: none;
    color: #000000de;
    text-decoration: none;
}

.exceptionContainer {
    display: flex;
    flex-direction: column;
}

.exceptionContainer p {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%; 
    margin: 0;
}

@media (min-width: 768px) {
    .exceptionContainer {
        flex-direction: row;
        gap: 13px;
    }
}

.ExceptionWidth {
    width: 3.6%;
}

@media (max-width: 1280px) {
    .ExceptionWidth {
        width: 4%;
    }
}